<template>
  <div>
    <a-modal
      v-model="visibleModal"
      class="mod"
      :title="isEdit ? 'Form Edit Predicate' : 'Form Create Predicate'"
      @cancel="toggleModal()"
      centered
    >
      <form-predicate :newData="newData" @handle-change="handleChange" :isSubmit="isSubmit" @handle-validate="handleValidate" />
      <template slot="footer">
        <a-button size="large" key="back" @click="toggleModal()">
          Cancel
        </a-button>
        <a-button
          size="large"
          key="submit"
          type="primary"
          :loading="loadingActionModal"
          @click="handleOkModal"
        >
          {{ isEdit ? "Edit" : "Create" }} Now
        </a-button>
      </template>
    </a-modal>
    <div class="d-flex align-items center">
      <div class="ml-auto">
        <a-button
          @click.prevent="toggleModal()"
          size="large"
          type="primary"
          ><a-icon type="plus" />Create Predicate</a-button
        >
      </div>
    </div>
    <div class="mt-4">
      <a-table
        :columns="columns"
        :data-source="dataTable"
        :pagination="pagination"
        :loading="loadingTable"
        bordered
      >
        <div slot="action" slot-scope="value, record">
          <a-button
            @click.prevent="toggleModal(record)"
            class="mr-3 text-warning border border-warning"
            size="large"
          >
            <a-icon type="edit" />Edit
          </a-button>
          <a-button
            @click.prevent="handleDelete(record)"
            class="text-danger border border-danger"
            size="large"
          >
            <a-icon type="delete" />Delete
          </a-button>
        </div>
      </a-table>
    </div>
  </div>
</template>

<script>
const FormPredicate = () => import('@/components/app/Admin/Master/Form/Predicate')
const columns = [
  {
    title: 'Name',
    dataIndex: 'nama',
    key: 'nama',
    align: 'center',
  },
  {
    title: 'Alias',
    dataIndex: 'alias',
    key: 'alias',
    align: 'center',
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    scopedSlots: { customRender: 'action' },
    align: 'center',
  },
]
export default {
  components: { FormPredicate },
  data() {
    return {
      pagination: { current: 1 },
      dataTable: [],
      columns,
      loadingTable: false,
      visibleModal: false,
      newData: {
        nama: null,
        alias: null,
      },
      loadingActionModal: false,
      isEdit: false,
      isSubmit: false,
      editRecord: {},
    }
  },
  methods: {
    toggleModal(data) {
      this.visibleModal = !this.visibleModal
      if (!this.visibleModal) {
        this.newData = {
          nama: null,
          alias: null,
        }
        this.isEdit = false
        this.editRecord = {}
      }

      if (data) {
        this.isEdit = true
        const { nama, alias } = data
        this.newData = { nama, alias }
        this.editRecord = data
      }
    },
    handleDelete(data) {
      this.$confirm({
        title: 'Warning',
        content: (
          <div>Are you sure to delete this predicate?</div>
        ),
        onOk: async () => {
          try {
            await this.$store.dispatch('master/DELETE_PREDICATE', { id: data.id })
            this.$notification.success({
              message: 'Success',
              description:
                'Predicate is deleted.',
            })
          } catch (err) {
            console.log(err)
            this.$notification.error({
              message: 'Error.',
              description: 'Predicate cannot be deleted.',
            })
          }
          this.fetchDataTable()
        },
        onCancel: () => {
        },
        centered: true,
        icon: 'warning',
        okType: 'danger',
        okText: 'Delete',
      })
    },
    handleChange(payload) {
      const { value, column } = payload
      this.newData[column] = value
    },
    handleOkModal() {
      this.isSubmit = true
    },
    handleValidate(payload) {
      if (payload) {
        this.loadingActionModal = true
        this.$confirm({
          title: 'Warning',
          content: (
            <div>Are you sure to { this.isEdit ? 'edit this' : 'add new'} predicate?</div>
          ),
          onOk: async () => {
            const status = this.isEdit ? 'edited' : 'added'
            try {
              const dispatchStatus = this.isEdit ? 'PUT' : 'POST'
              const payloadEdit = this.isEdit ? { id: this.editRecord.id } : {}
              await this.$store.dispatch(`master/${dispatchStatus}_PREDICATE`, { newData: this.newData, ...payloadEdit })
              this.$notification.success({
                message: 'Success',
                description:
                  `Predicate is ${status}.`,
              })
            } catch (err) {
              console.log(err)
              this.$notification.error({
                message: 'Error.',
                description: `Predicate cannot be ${status}.`,
              })
            }
            this.fetchDataTable()
            this.toggleModal()
            this.isSubmit = false
            this.loadingActionModal = false
          },
          onCancel: () => {
            this.isSubmit = false
            this.loadingActionModal = false
          },
          centered: true,
          icon: 'warning',
          okType: 'primary',
          okText: this.isEdit ? 'Edit' : 'Add',
        })
      } else {
        this.$notification.error({
          message: 'Error.',
          description: 'All field is required.',
        })
        this.isSubmit = false
      }
    },
    async fetchDataTable() {
      try {
        const data = await this.$store.dispatch('master/FETCH_PREDICATE', { page: this.pagination.current })
        this.dataTable = data.map(dat => ({ ...dat, key: dat.id }))
        const total = this.dataTable.length
        this.pagination = { ...this.pagination, total }
        return new Promise((resolve) => resolve())
      } catch (err) {
        return new Promise((resolve, reject) => reject(err))
      }
    },
  },
  created() {
    this.fetchDataTable()
  },
}
</script>

<style>
</style>
